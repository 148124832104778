var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"3.5.62"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';

if (
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production' ||
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'pre-production'
) {
  Sentry.init({
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    autoSessionTracking: true,
    enableTracing: true,
    tracesSampleRate: 0.01,
    sampleRate: 0.1,
  });
}
